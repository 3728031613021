html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}