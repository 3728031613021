html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}
/*# sourceMappingURL=index.3f56f9a0.css.map */
